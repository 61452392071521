<script setup lang="ts">
import type { mastodon } from '#types'

const { account, context } = defineProps<{
  account: mastodon.v1.Account
  context?: 'followedBy' | 'following'
}>()

type Relationship = Partial<mastodon.v1.Relationship>
const relationship = ref<Relationship>({})
const { data, pending } = await useFetch<mastodon.v1.Relationship[]>('/api/accounts/relationships', {
  headers: useRequestHeaders(['cookie']),
  query: { id: account.id },
})

watch(data, () => {
  relationship.value = data?.value?.[0] ?? {}
}, { immediate: true })

async function update() {
  const value = relationship.value
  let action: string
  if (value.blocking) {
    value.blocking = !value.blocking
    action = value.blocking ? 'block' : 'unblock'
  }
  else if (value.muting) {
    value.muting = !value.muting
    action = value.muting ? 'mute' : 'unmute'
  }
  else {
    const unfollow = value.following || value.requested
    if (unfollow) {
      value.following = false
      value.requested = false
    }
    else if (account.locked) {
      value.requested = true
    }
    else {
      value.following = true
    }
    action = unfollow ? 'unfollow' : 'follow'
  }
  relationship.value = await $fetch<mastodon.v1.Relationship>(`/api/accounts/${account.id}/${action}`, { method: 'POST' })
}
</script>

<template>
  <button :enable="!pending" @click="update()">
    <template v-if="relationship?.blocking">
      <span>{{ $t('account-blocking') }}</span>
    </template>
    <template v-if="relationship?.muting">
      <span>{{ $t('account-muting') }}</span>
    </template>
    <template v-else-if="relationship ? relationship.following : context === 'following'">
      <span>{{ relationship?.followedBy ? $t('account-mutuals') : $t('account-following') }}</span>
    </template>
    <template v-else-if="relationship?.requested">
      <span>{{ $t('account-follow_requested') }}</span>
    </template>
    <template v-else-if="relationship ? relationship.followedBy : context === 'followedBy'">
      <span>{{ $t('account-follows_you') }}</span>
    </template>
    <template v-else>
      <span>{{ account.locked ? $t('account-request_follow') : $t('account-follow') }}</span>
    </template>
  </button>
</template>
