import { decode } from 'tiny-decode'
import type { ElementNode, Node } from 'ultrahtml'
import { ELEMENT_NODE, TEXT_NODE } from 'ultrahtml'
import type { VNode } from 'vue'
import { Fragment, h, isVNode } from 'vue'
import { RouterLink } from 'vue-router'
import type { ContentParseOptions } from './content-parse'
import { parseMastodonHTML } from './content-parse'
import { nodeIsMediaViewer } from './media-item-html'
import type { ContentMedia } from '~/types/content-media'
import { AccountHoverHandler, ContentAudioPlayer, ContentImage, ContentMediaViewer, ContentVideoPlayer } from '#components'

const UserLinkRE = /^(?:https:\/)?\/([^/]+)\/@([^/]+)$/
const TagLinkRE = /^https?:\/\/([^/]+)\/tags\/([^/]+)\/?$/

/**
 * Raw HTML to VNodes
 */
export function contentToVNode(content: string, options?: ContentParseOptions): VNode {
  const tree = parseMastodonHTML(content, options)
  return h(Fragment, (tree.children as Node[] || []).map(n => treeToVNode(n)))
}

function nodeToVNode(node: Node): VNode | string | null {
  if (node == null) {
    return null
  }
  if (node.type === TEXT_NODE) {
    return node.value
  }
  if ('children' in node) {
    if (node.name === 'a' && (node.attributes.href?.startsWith('/') || node.attributes.href?.startsWith('.'))) {
      const { href, target: _, ...attrs } = node.attributes
      node.attributes.to = href
      return h(
        RouterLink as any,
        attrs,
        () => node.children.map(treeToVNode)
      )
    }
    return h(
      node.name,
      node.attributes,
      node.children.map(treeToVNode)
    )
  }
  return null
}

function treeToVNode(root: Node): VNode | string | null {
  if (!root) {
    return null
  }
  if (root.type === TEXT_NODE) {
    return decode(root.value)
  }
  if ('children' in root) {
    const node = handleNode(root)
    if (node == null) {
      return null
    }
    if (isVNode(node)) {
      return node
    }
    return nodeToVNode(node)
  }
  return null
}

function handleNode(el: Node) {
  return handleMedia(el) ||
    handleMention(el) ||
    handleTag(el) ||
    el
}

function handleMedia(node: Node) {
  if (nodeIsMediaViewer(node)) {
    const { type, mediaItems, publishId } = nodeToMediaViewerProps(node)
    return h(ContentMediaViewer, { type, mediaItems, publishId })
  }
  if (nodeIsMedia(node)) {
    const media = nodeToMedia(node)
    switch (media?.type) {
      case 'image': return h<{ media: ContentMedia }>(ContentImage, { media })
      case 'video': return h<{ media: ContentMedia }>(ContentVideoPlayer, { media })
      case 'audio': return h<{ media: ContentMedia }>(ContentAudioPlayer, { media })
    }
  }
  return undefined
}

function handleMention(el: Node) {
  if (el.name === 'a' && el.attributes.class?.includes('mention')) {
    const href = el.attributes.href
    if (href) {
      const matchUser = href.match(UserLinkRE)
      if (matchUser) {
        const [, server, username] = matchUser
        const handle = `${username}@${server.replace(/(.+\.)(.+\..+)/, '$2')}`
        el.attributes.href = `/${server}/@${username}`
        addBdiNode(el)
        return h(AccountHoverHandler, { handle }, () => nodeToVNode(el))
      }
    }
  }
  return undefined
}

function handleTag(el: Node) {
  if (el.name === 'a' && el.attributes.class?.includes('hashtag')) {
    const href = el.attributes.href
    if (href) {
      const matchTag = href.match(TagLinkRE)
      if (matchTag) {
        const [, , name] = matchTag
        addBdiNode(el)
        el.attributes.href = `/${useRuntimeConfig().public.domain}/tags/${name}`
      }
    }
  }
  return undefined
}

function addBdiNode(node: Node) {
  if (node.children.length === 1 && node.children[0].type === ELEMENT_NODE && node.children[0].name === 'bdi') { return }

  const children = node.children.splice(0, node.children.length)
  const bdi = {
    name: 'bdi',
    parent: node,
    loc: node.loc,
    type: ELEMENT_NODE,
    attributes: {},
    children
  } satisfies ElementNode
  children.forEach((n: Node) => { n.parent = bdi })
  node.children.push(bdi)
}
