<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{
  account: mastodon.v1.Account
}>()

const isSelf = useSelfAccount(() => props.account)
const enable = computed(() => !isSelf.value && useAuth().loggedIn.value)
const openConversation = useState<mastodon.v1.Account | null>('open-conversation')

function join() {
  openConversation.value = props.account
}
</script>

<template>
  <button v-if="enable" @click="join()">
    <span>{{ $t('action-new_message') }}</span>
  </button>
</template>
