import { ELEMENT_NODE, parse, render } from 'ultrahtml'
import { transformSync, type Transform } from './transform'

// A tree transform for sanitizing elements & their attributes.
type AttrSanitizers = Record<string, (value: string | undefined) => string | undefined>

const HTTP_PROTOCOLS = filterHref(['http', 'https'])

export function sanitize(allowedElements: Record<string, AttrSanitizers>): Transform {
  return (node) => {
    if (node.type !== ELEMENT_NODE) {
      return node
    }
    if (!Object.prototype.hasOwnProperty.call(allowedElements, node.name)) {
      return null
    }
    const attrSanitizers = allowedElements[node.name]
    const attrs = {} as Record<string, string>
    for (const [name, func] of Object.entries(attrSanitizers)) {
      const value = func(node.attributes[name])
      if (value !== undefined) { attrs[name] = value }
    }
    node.attributes = attrs
    return node
  }
}

function keep(value: string | undefined) {
  return value
}

function set(value: string) {
  return () => value
}

function filterHref(values: string[]) {
  const protocols = new Set<string>(values)
  return (href: string | undefined) => {
    if (href === undefined) {
      return undefined
    }

    // Allow relative links
    if (href.startsWith('/') || href.startsWith('.')) {
      return href
    }

    let url
    try {
      url = new URL(href)
    } catch (err) {
      if (err instanceof TypeError) {
        return undefined
      }
      throw err
    }

    if (protocols.has(url.protocol)) {
      return url.toString()
    }
    return '#'
  }
}

export const sanitizer = {
  oembed: sanitize({
    audio: {
      controls: keep
    },
    embed: {
      src: HTTP_PROTOCOLS,
      height: keep,
      type: keep,
      width: keep
    },
    iframe: {
      src: HTTP_PROTOCOLS,
      allowfullscreen: keep,
      frameborder: keep,
      height: keep,
      scrolling: keep,
      width: keep,
      sandbox: set('allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-forms'),
    },
    source: {
      src: HTTP_PROTOCOLS,
      type: keep
    },
    video: {
      controls: keep,
      height: keep,
      loop: keep,
      width: keep
    },
  })
}

export function sanitizeHtml(html: string, transforms: Transform[]): Promise<string> {
  return render(transformSync(parse(html), transforms))
}
