<script setup lang="ts">
import type { mastodon } from '#types'

const { account, context } = defineProps<{
  account: mastodon.v1.Account
  context?: 'followedBy' | 'following'
}>()

const isSelf = useSelfAccount(() => account)
const enable = computed(() => !isSelf.value && useAuth().loggedIn.value)
</script>

<template>
  <AccountRelationship v-if="enable" :account="account" :context="context" />
</template>
