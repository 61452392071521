<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{ account: mastodon.v1.Account }>()
const { account } = toRefs(props)
const name = computed(() => getDisplayName(props.account, { rich: true }))
const to = computed(() => getAccountRoute(props.account))
const theme = useTheme(computed(() => props.account.theme))
</script>

<template>
  <UiPopup trigger="mouseenter" theme="indi-clear" :style="theme" class="theme account-hover">
    <template #trigger-title>
      <slot />
    </template>
    <UiBar bg class="account-pop-card theme" :style="theme">
      <NuxtLink :to="to" class="account-pop-link">
        <AccountAvatar :account="account" sizes="96px" />
        <div class="account-pop-info">
          <h3>{{ name }}</h3>
          <AccountStats :account="{ ...account, statusesCount: 17493 }" short />
        </div>
      </NuxtLink>
      <div class="account-pop-actions">
        <AccountFollowButton :account="account" />
        <AccountMessageButton :account="account" />
      </div>
    </UiBar>
  </UiPopup>
</template>

<style>
.account-pop-card {
  --badge-size: calc(var(--base-size) * 2);
  --badge-half: calc(var(--badge-size) / 2);
  --c: var(--color-bg);
  padding: var(--padding-small);
  padding-inline-end: var(--padding-base);
  display: grid;
  grid-template-columns: var(--badge-size) 1fr;
  grid-template-rows: 1fr var(--button-height);
  gap: 0 var(--padding-small);

  .account-pop-link {
    display: grid;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
    font-size: var(--font-size-body);

    .account-avatar {
      grid-column: 1;
      grid-row: 1 / 3;
    }

    .account-pop-info {
      grid-column: 2;
      grid-row: 1;
    }

    h3 {
      font-size: var(--h4);
      margin: 0;
    }
  }

  .account-pop-actions {
    --button-font-size: var(--font-size-sm);
    --color-bg: var(--color-panel);
    grid-column: 2;
    grid-row: 2;
    margin-inline-start: calc(var(--padding-mini) * -1);
    display: flex;
    gap: var(--padding-mini);

    button {
      background-color: color-mix(in oklch, var(--color-panel) 40%, transparent);
    }
  }

  a,
  a:hover {
    text-decoration: none;
  }
}
</style>
